import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
html {
    height: 100%;
}

:root { 
    --bannerTitle: 34px;
    --borderSpacing: 30px;
    --menuWidth: 100vw;
    --blockquote: 20px;
    --background: black;
    --secondaryBackground: #0a0a0a;
    --footerBg: #1b1b1b;
    --primary: rgb(178, 119, 70);
    --primaryAlpha: rgb(178, 119, 70, 0.3);
    --bodyColor: #d7dbdd;
    --inActive: #555;
    --letterSpacing: -0.075rem;
    --boxShadow: 0px 15px 22px 3px rgba(0, 0, 0, 0.55);
    --textShadow: 0px 0px 5px rgba(0, 0, 0, 1);
    --p: 14px;
    --h2: 20px;
    --h3: 19px;
    --h4: 18px;
    --h5: 17px;
    --h6: 16px;
    --gap: 40px;
    --sectionMargin: 60px;
    --containerPadding: 30px;

    --primaryFont: 'Inter', sans-serif;
    --secondaryFont: 'Roboto Flex', sans-serif;

    body {
        //height: auto;
        width: 100%;
        padding: 0;
        margin: 0;
        color: white;
        background-color: var(--background);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;
        font-family: 'Inter', sans-serif;
        font-size: var(--p);

        @media screen and (min-width: 768px) {
            font-size: var(--p)+2;
        }

        p {
            color: var(--bodyColor);
        }

        & > div {
            height: 100%;

            & > div {
                height: 100%;
            }
        }

        main {
            height: inherit;
        }
    }
}

#___gatsby {
    height: 100%;
}

/** Typography */
h2 {
    display: inline-block;
    position: relative;
    font-size: 2em;
    font-weight: bolder;
    padding-right: 12px;
    margin-bottom: 30px;

    &:after {
        display: block;
        content: '';
        width: 0.25em;
        height: 0.25em;
        border-radius: 50%;
        background-color: var(--primary);
        position: absolute;
        right: 2px;
        bottom: 7px;

        @media screen and (min-width: 768px) {
            width: 8px;
            height: 8px;
            right: -2px;
            bottom: 12px;
        }
    }
}

.container {
    padding: 0 var(--containerPadding);
    height: 100%;

    @media screen and (min-width: 768px) {
        padding: 0 150px;
        height: 85%;
    }

    &__tight {
        //max-width: 1400px;
    }

    &__scroll {
        overflow-x: scroll;
        display: flex;
        scroll-snap-type: x mandatory;
        padding-bottom: 10px;

        &::-webkit-scrollbar {
            width: 14px;
            height: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--primaryAlpha);
            border-radius: 0px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: var(--primary);
        }
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 0px;
        }

        @media (min-width: 1200px) {
            overflow-x: visible;
        }
    }
}

.gradient:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50vh;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    background-position: bottom;
    background-size: 100% 180px;
    background-repeat: no-repeat;
    z-index: 0;
}

.image-darken {
  filter: brightness(0.5); /* Puedes ajustar el valor para cambiar la intensidad del oscurecimiento */
}
`
