exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bones-tsx": () => import("./../../../src/pages/bones.tsx" /* webpackChunkName: "component---src-pages-bones-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cuts-tsx": () => import("./../../../src/pages/cuts.tsx" /* webpackChunkName: "component---src-pages-cuts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leathers-tsx": () => import("./../../../src/pages/leathers.tsx" /* webpackChunkName: "component---src-pages-leathers-tsx" */)
}

