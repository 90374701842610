import * as React from "react"
import type { GatsbyBrowser } from "gatsby"
import { Helmet } from "react-helmet"
import { GlobalStyle } from "./src/styles/GlobalStyle";
import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from "@chakra-ui/react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
	fonts: {
		body: "Inter, sans-serif",
		heading: "Roboto Flex, sans-serif"
	},
})

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return (
	<>
		<Helmet>
			<link rel="preconnect" href="https://fonts.googleapis.com"></link>
			<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
			<link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&family=Roboto+Flex:opsz,wght@8..144,800&display=swap" rel="stylesheet"></link>
			<meta name="google-site-verification" content="nLiK_CCbjS8_P3nWx7m5y8ClT_hpCKT1JwxPCvm779c" />
			<title>Runfo S.A. - Feeding the world</title>
			<meta name="description" content="We are Runfo. A company with more than 50 years of experience in the production and sale of beef cuts and by-products."/>
		</Helmet>
		<ChakraProvider theme={theme}>	
			<GlobalStyle />
			{element}
		</ChakraProvider>
	</>
  )
}